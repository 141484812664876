<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page"/>
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import {CustomerService as Service} from '@/services'

export default {
  name: 'InvestorList',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  data () {
    return {
      table: {
        filter: [
          {
            name: 'Kullanıcı Adı',
            field: 'username',
            type: 'input'
          },
          {
            name: 'İsim',
            field: 'name',
            type: 'input'
          },
          {
            name: 'Soyisim',
            field: 'surname',
            type: 'input'
          },
          {
            name: 'User ID',
            field: 'userId',
            type: 'input'
          },
          {
            type: 'switch',
            value: null,
            field: 'isProblem',
            name: 'Problemli'
          }
        ],
        page: {
          title: 'Yatırımcı Listesi',
          icon: 'account',
          actions: [
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => { this.$refs.table.loadAsyncData() }
            }
          ]
        },
        settings: {
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'username',
              label: 'Kullanıcı Adı',
              sortable: true
            },
            {
              field: 'name',
              label: 'Ad',
              sortable: true
            },
            {
              field: 'surname',
              label: 'Soyad',
              sortable: true
            },
            {
              field: 'userId',
              label: 'User ID',
              sortable: true
            },
            {
              field: 'isProblem',
              label: 'Problemli mi?',
              sortable: true,
              renderHtml: true,
              viewMethod: val => `<span class="tag ${val ? 'is-danger' : 'is-success'}">${val ? 'Evet' : 'Hayır'}</span>`
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              sortable: false,
              width: '100px',
              actions: [
                {
                  title: 'Detay',
                  type: 'success',
                  column: 'row',
                  action: data => this.$router.push({ name: 'investorDetail', params: { id: data.uuid } })
                }
              ]
            }
          ]
        }
      }
    }
  },
  mounted(){
    this.getRoles()
    this.getSites()
  },
  methods: {
    getRoles () {
      RoleService.all({status: 1}).then(({ data }) => {
        this.table.filter.unshift({
          type: 'select',
          value: null,
          data,
          field: 'roleId',
          name: 'Rol',
          id: 'id'
        })
      })
    },
    getSites () {
      SiteService.all({status: 1}).then(({ data }) => {
        this.table.filter.unshift({
          type: 'select',
          value: null,
          data,
          field: 'siteId',
          name: 'Site'
        })
      })
    },
  }
}
</script>
